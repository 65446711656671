<script setup>
const props = defineProps(["short"]);

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const scrollTo = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

// Watch for changes in isMenuOpen to enable/disable scroll
watch(isMenuOpen, (newValue) => {
  document.body.style.overflow = newValue ? "hidden" : "";
});

// Ensure scrolling is re-enabled when the component is unmounted
onUnmounted(() => {
  document.body.style.overflow = "";
});

const socials = [
  {
    link: "https://www.facebook.com/Dnyumeleinteligence",
    image: "/images/facebook.svg",
  },
  {
    link: "https://www.linkedin.com/showcase/dny-ai/",
    image: "/images/lI.svg",
  },
  {
    link: "https://www.instagram.com/dnyaicz/",
    image: "/images/instagram.svg",
  },
];

const menuItems = [
  { link: "o-akci", text: "O akci" },
  { link: "program", text: "Program" },
  { link: "organizatori", text: "Organizátoři" },
  { link: "kontakt", text: "Kontakt" },
];
</script>

<template>
  <nav
    :class="['menu', { high: !short, short: short }]"
    aria-label="Main Navigation"
  >
    <div class="menu__logo">
      <NuxtLink to="/">
        <img src="/images/logo/dny_ai.svg" alt="Logo" />
      </NuxtLink>
    </div>
    <div class="menu__items">
      <p v-for="item in menuItems" :key="item.link">
        <NuxtLink :to="`/#${item.link}`">
          {{ item.text }}
        </NuxtLink>
      </p>
    </div>
    <div class="menu__hamburger" @click="toggleMenu">
      <div :class="{ bar: true, active: isMenuOpen }"></div>
      <div :class="{ bar: true, active: isMenuOpen }"></div>
      <div :class="{ bar: true, active: isMenuOpen }"></div>
    </div>
    <div v-if="isMenuOpen" class="menu__overlay" @click="toggleMenu">
      <div class="menu__overlay-bg">
        <div class="menu__overlay-items">
          <div></div>
          <div class="text-links">
            <p v-for="item in menuItems" :key="item.link">
              <a @click.prevent="scrollTo(item.link)">
                {{ item.text }}
              </a>
            </p>
          </div>
          <div class="menu__overlay-social">
            <a
              v-for="social in socials"
              :key="social.link"
              :href="social.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="social.image" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.menu {
  min-height: 140px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;
  box-sizing: border-box;
  z-index: 2;
  @media (max-width: 1300px) {
    padding: 0 32px;
    min-height: 100px;
  }
  @media (max-width: 970px) {
    padding: 0 24px;
  }
}
.high {
  height: 12.5vw;
}
.short {
  position: fixed;
  background-color: white;
  top: 0;
}
.menu__items {
  display: flex;
  font-weight: 300;
  text-transform: uppercase;
  p {
    padding-left: 48px;
    a {
      text-decoration: none;
      &:hover {
        transition-duration: 350ms;
      }
    }
  }
  @media (max-width: 970px) {
    display: none;
  }
}

.menu__items a {
  position: relative;
}

.menu__items a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: currentColor;
  transition: width 0.3s ease-out;
}

.menu__items a:hover::after {
  width: 100%;
}

.menu__logo,
.menu__hamburger {
  position: relative;
  z-index: 2;
}

.menu__hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  cursor: pointer;
  @media (max-width: 970px) {
    display: flex;
  }
  .bar {
    width: 30px;
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease;
  }
  .bar.active:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  .bar.active:nth-child(2) {
    opacity: 0;
  }
  .bar.active:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}
.menu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url("/images/igor-omilaev-unsplash.jpg");
  background-size: cover;
  background-position: center center;
  transition-duration: 350ms;
}
.menu__overlay-bg {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 24px;
}
.menu__overlay-items {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  .text-links {
    display: flex;
    flex-direction: column;
    p {
      a {
        text-decoration: none;
        font-size: 24px;
      }
    }
  }
}

.menu__overlay-social img {
  margin-right: 24px;
}
</style>
