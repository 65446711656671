export function useContrastColor(backgroundColor) {
  // Remove the hash if it's there
  let hex = backgroundColor.replace("#", "");

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate brightness using the luminance formula
  let brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return either 'black' or 'white' depending on brightness
  return brightness > 128 ? "black" : "white";
}
